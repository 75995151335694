import React, {useMemo, useState} from "react";
import {getImage} from "gatsby-plugin-image";
import find from "lodash/find"
import Cookies from "universal-cookie";

const MainContext = React.createContext();

const MainProvider = ({children, pageContext}) => {
    const getImageFromName = (images, name) => {
        const image =
            find(images || [], (img) => {
                return img.node.base === name;
            }) || {};

        if (!image) {
            return null;
        }
        return getImage(image.node);
    };
    const translate = (key, params = null, locale = '', defaultValue = '') => {
        return key;
    }
    const url = (to, lang = '') => {
        return to;
    }

    const [initial, setInitial] = useState({opacity: 0, scale: 1,});
    const [animate, setAnimate] = useState({opacity: 1, scale: 1, transition: {duration: 0,}});
    const [exit, setExit] = useState({opacity: 0, scale: 1, transition: {delay: 0, duration: 0},});

    const cookies = useMemo(() => {
        return new Cookies();
    }, [])
    const [preloadedCookie, setPreloadedCookie] = useState(cookies.get('preloader'));
    const setCookiePreloader = () => {
        const options = {path: '/', maxAge: 31536000, secure: process.env.GATSBY_ENV !== 'local'};
        cookies.set('preloader', '1', options);
        setPreloadedCookie(1);
    }
    const noAnimation = () => {
        setInitial({opacity: 1, scale: 1,})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0,}})
        setExit({opacity: 1, scale: 1, transition: {delay: 0, duration: 0},})
    }
    const defaultAnimation = () => {
        setInitial({opacity: 0, scale: 1,})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0,}})
        setExit({opacity: 0, scale: 1, transition: {delay: 0, duration: 0},})
    }
    const scaleAnimation = () => {
        setInitial({opacity: 0, scale: 0.90})
        setAnimate({
            opacity: 1, scale: 1, transition: {
                duration: 0.3, opacity: {
                    duration: 0.8
                }
            }
        })
        setExit({opacity: 1, scale: 1, transition: {delay: 0, duration: 0},})
    }
    const fadeOutProjectAnimation = () => {
        setInitial({opacity: 0, scale: 1,})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0,}})
        setExit({opacity: 1, scale: 1, transition: {delay: 1, duration: 3},})
    }
    const IS_SSR = () => typeof window === 'undefined'

    return (
        <MainContext.Provider
            value={{
                getImageFromName,
                translate,
                delayAnimationPreloader: preloadedCookie ? 0 : 3.20,
                showPreloader: parseInt(preloadedCookie) !== 1,
                setCookiePreloader,
                url,
                IS_SSR,
                singleLang: true,
                animation: {
                    initial,
                    setInitial,
                    animate,
                    setAnimate,
                    exit,
                    setExit,
                    noAnimation,
                    defaultAnimation,
                    scaleAnimation,
                    fadeOutProjectAnimation
                }
            }}
        >
            {children}
        </MainContext.Provider>
    );
};

export default MainContext;
export {MainProvider};
