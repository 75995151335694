import React from 'react';
import {MainProvider} from "./src/context/MainContext";

/**
 * @type {import('gatsby').GatsbySSR['onRenderBody']}
 */
export const onRenderBody = ({setHtmlAttributes, setHeadComponents}) => {
    setHtmlAttributes({lang: `it`})
    setHeadComponents(HeadComponents)
}


export const wrapPageElement = ({element, props}) => (
    <MainProvider  {...props}>
        {element}
    </MainProvider>
);

const isDevelop = () => process.env.GATSBY_ENV === "local";
const gaTag = (first = false) => {
    const ids = process.env.GATSBY_GA || ''
    const params = ids.split(',')
    let gtags = ''
    if (first) {
        return params[0] || ''
    }
    params.forEach(gtag => {
        gtags += `gtag('config', '${gtag}');`
    })
    return gtags
};

const HeadComponents = [
    <link rel="preconnect" href="https://fonts.googleapis.com"/>,
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>,
    <script key="ga-url" type="text/plain" async={true} defer={true}
            data-cookiecategory={isDevelop() ? 'develop' : 'analytics'}
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTag(true)}`}/>,
    <script key="ga-script" type="text/plain" async={true} defer={true}
            data-cookiecategory={isDevelop() ? 'develop' : 'analytics'}  dangerouslySetInnerHTML={{ __html: `
                if(typeof window !== 'undefined') {
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments)}
                    gtag('js', new Date());
                    ${gaTag()}
                }
            ` }} >
    </script>,
    <script type="text/javascript" dangerouslySetInnerHTML={{
        __html: `
                if(typeof window !== 'undefined') {
                    function gtag_report_conversion(url) {
                        var callback = function () {
                            if (typeof(url) != 'undefined') {
                                window.location = url;
                            }
                        };
                        gtag('event', 'conversion', {
                            'send_to': 'AW-978530713/wsMcCMrwmPsBEJnjzNID',
                            'event_callback': callback
                        });
                        return false;
                    }
                    window.gtag_report_conversion = gtag_report_conversion;
                }
            `
    }}>
    </script>

]