/*
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
*/
import './src/assets/scss/app.scss'
window.cc = require('vanilla-cookieconsent')
const axios = require("axios").default


export { wrapPageElement } from "./gatsby-ssr";

export const onInitialClientRender = () => {
    axios.defaults.baseURL = process.env.GATSBY_API_URL
    axios.defaults.headers.common = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-Token-Mailer": process.env.GATSBY_MAILER_TOKEN
    };
    window.gtag = window.gtag || function(){};
}